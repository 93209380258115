import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

/**
 * Properties -> React.ReactNode
 * Properties = {
 *  className   : Void | String
 *  hasError    : Boolean
 *  label       : Void | Null | String
 *  maxLength   : Void | Null | Integer
 *  onChange    : Void | Null | (React.ChangeEventHandler HTMLInputElement)
 *  placeholder : Void | Null | String
 *  value       : Void | String
 * }
 */
export default function TextInput(properties) {

    const { className = '' }    = properties;
    const { hasError }          = properties;
    const { label }             = properties;
    const { maxLength }         = properties;
    const { onChange }          = properties;
    const { placeholder }       = properties;
    const { value = '' }        = properties;

    let extraClassName = '';
    if(hasError) {

        // console.log("found error on label " + label);

        extraClassName = ' border-red-500';
    }

    return <div className={`flex flex-col gap-2 ${className}`}>
        { label && <label>{label}</label> }
        <input { ... {

            maxLength:      maxLength,
            onChange:       onChange,
            placeholder:    placeholder,
            type:           'text',
            value:          value ?? '',

            className: [
                'border',
                'border-gray-300',
                'box-content',
                'flex',
                'flex-row',
                'items-center',
                'justify-center',
                'px-4',
                'py-3',
                'rounded-xl',
                extraClassName
            ].join(' ')

        } } />
    </div>

}
