import React, { Fragment, useState, useEffect } from 'react';
import { useCalendarEventContext } from '../Context/CalendarEventContext';
import { useCalendarEventContextSelector } from 'Context/CalendarEventContextSelector';
import { ChevronRightIcon, ChevronLeftIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import EventDetailsDialog from '../Components/EventDetailsDialog';
import { Listbox } from '@headlessui/react'
import { formatDateFromISO } from '../Utils/date';
import TextInput from '../Components/Forms/Controls/TextInput';
import { CalendarIcon } from '@heroicons/react/24/outline';

function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    return [d.getUTCFullYear(), weekNo];
}

function downloadAsCSV(data, filename) {
    let csv = '\uFEFF' + data.map(e => `"${e.join("\";\"")}"`).join("\n") + "\n";

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    let url = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename + ".csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


function convertToCSV(filteredEvents, calendarEvents, calendarEventsDetails, participants) {
    
        let csv = [];
        csv.push(['Ingediend voor', 'Ingediend op', 'Type', 'Bedrijfsnaam', 'Naam', 'Email', 'Aantal deelnemers', 'Lunch', 'Opmerkingen', 'Status']);
    
        for(let event of filteredEvents) {
            let eventDetails = calendarEventsDetails[event.$id];
            let status = "pending";
            let companyName = "";
            let name = "";
            let email = "";
            let count = 0;
            let lunch = "n.v.t";
            let comments = "";
            if (eventDetails && Object.keys(eventDetails).length > 0) {
                let firstEvent = Object.values(eventDetails)[0];
                status = event.status;
                switch (event.$$type) {
                    case 'aanpakker':
                        let firstParticipant = Object.values(participants[event.$id] || {})[0];
                        companyName = firstEvent.organizationName;
                        if(firstParticipant) {
                            name = `${firstParticipant.firstName} ${firstParticipant.lastName}`;
                            email = firstParticipant.emailAddress;
                            count = Object.values(participants[event.$id]).length;
                        }
                        break;
                    case 'teamuitje':
                        companyName = firstEvent.organizationName;
                        name = `${firstEvent.firstName} ${firstEvent.lastName}`;
                        email = firstEvent.emailAddress;
                        count = firstEvent.amountOfParticipants;
                        lunch = firstEvent.lunch ? "Ja" : "Nee";
                        comments = firstEvent.comments;
                        break;
                    case 'heldenavond':
                        name = `${firstEvent.firstName} ${firstEvent.lastName}`;
                        email = firstEvent.emailAddress;
                        count = 1;
                        break;
                    case 'maatwerk':
                        name = `${firstEvent.primaryContactFirstName} ${firstEvent.primaryContactLastName}`;
                        email = firstEvent.primaryContactEmailAddress;
                        count = 1;
                        lunch = firstEvent.lunch ? "Ja" : "Nee";
                        break;
                }
            }
            let daypart = event.daypart == "morning" ? "ochtend" : "middag";
            daypart = event.daypart ? `(${daypart})` : '';
            csv.push([formatDateFromISO(event.date), formatDateFromISO(event.$createdAt),`${event.$$type} ${daypart}`, companyName, name, email, count, lunch, comments, status]);
        }
    
        return csv;
}

export default function AdminRequestList() {

    const [statusFilter, setStatusFilter] = useState('pending');
    const [typeFilter, setTypeFilter] = useState('');
    const [periodFilter, setPeriodFilter] = useState('complete');
    const [lunchFilter, setLunchFilter] = useState('all');

    const [selectedEvent, setSelectedEvent] = useState(null);
    const { unavailableDates, calendarEvents, calendarEventsDetails, participants, reload } = useCalendarEventContextSelector();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const allItems = Object.values(calendarEvents || {}).reduce((col, v) => [...col, ...Object.values(v)], []);
    const [dateSort, setDateSort] = useState('desc');
    const [calendarDateSort, setCalendarDateSort] = useState('desc');
    const [sortBy, setSortBy] = useState('date');
    const [searchString, setSearchString] = useState('');
    

    // standaard is hier mijn aanname dat, als status niet ingevuld is, deze op pending staat
    const filterStatus = (event, status) => (event.status || "pending") === status;
    const filterType = (event) => event.$$type === typeFilter;


    let filteredItems = allItems.filter((event) => typeFilter ? filterType(event) : true).filter((event) => filterStatus(event, statusFilter)).sort((a,b) => {
        if(sortBy == 'date') {
            let dateA = new Date(a.$createdAt);
            let dateB = new Date(b.$createdAt);
            if(dateSort == 'desc') {
                return dateB - dateA;
            }
            return dateA - dateB;
        } else if(sortBy == 'calendarDate') {
            let dateA = new Date(a.date);
            let dateB = new Date(b.date);
            if(calendarDateSort == 'desc') {
                return dateB - dateA;
            }
            return dateA - dateB;
        }

    });

    if(searchString) {
        const searchStringFilter = (event) => {
            let eventDetails = calendarEventsDetails[event.$id];
            if(!eventDetails) {
                return false;
            }
            let status = "pending";
            let companyName = "";
            let name = "";
            let email = "";
            let count = 0;
            if (eventDetails && Object.keys(eventDetails).length > 0) {
                let firstEvent = Object.values(eventDetails)[0];
                status = event.status;
                switch (event.$$type) {
                    case 'aanpakker':
                        let firstParticipant = Object.values(participants[event.$id] || {})[0];
                        companyName = firstEvent.organizationName;
                        if(firstParticipant) {
                            name = `${firstParticipant.firstName} ${firstParticipant.lastName}`;
                            email = firstParticipant.emailAddress;
                            count = Object.values(participants[event.$id]).length;
                        }
                        break;
                    case 'teamuitje':
                        status = "pending";
                        companyName = firstEvent.organizationName;
                        name = `${firstEvent.firstName} ${firstEvent.lastName}`;
                        email = firstEvent.emailAddress;
                        count = firstEvent.amountOfParticipants;
                        break;
                    case 'heldenavond':
                        status = "rejected";
                        name = `${firstEvent.firstName} ${firstEvent.lastName}`;
                        email = firstEvent.emailAddress;
                        count = 1;
                        break;
                    case 'maatwerk':
                        name = `${firstEvent.primaryContactFirstName} ${firstEvent.primaryContactLastName}`;
                        email = firstEvent.primaryContactEmailAddress;
                        count = 1;
                        break;
                }
            }

            let _searchString = searchString.toLowerCase();
            name = `${name}`.toLowerCase();
            companyName = `${companyName}`.toLowerCase();
            email = `${email}`.toLowerCase();
            return name.includes(_searchString) || companyName.includes(_searchString) || email.includes(_searchString);
        }
        filteredItems = filteredItems.filter(searchStringFilter);
    }

    const dateFilter = (event) => {
        let date = new Date(event.date);
        let now = new Date();

        switch (periodFilter) {
            case 'currentMonth':
                return date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear();
            case 'currentWeek':
                return getWeekNumber(date) == getWeekNumber(now) && date.getFullYear() == now.getFullYear();
            default:
                return true;
        }
    }

    const lunchFilterFn = (event) => {
        if(lunchFilter == 'all') {
            return true;
        }



        let eventDetails = calendarEventsDetails[event.$id];
        if(!eventDetails) {
            return false;
        }
        let firstEvent = Object.values(eventDetails)[0];

        if(lunchFilter == 'lunch') {
            if(event.$$type != 'teamuitje' && event.$$type != 'maatwerk') {
                return false;
            }
            return firstEvent.lunch;
        } 

        if(lunchFilter == 'noLunch') {
            return !firstEvent.lunch;
        }
    }

    filteredItems = filteredItems.filter(dateFilter);
    filteredItems = filteredItems.filter(lunchFilterFn);

    const exportEvents = (filteredEvents) => {
        let csv = convertToCSV(filteredEvents, calendarEvents, calendarEventsDetails, participants);
        downloadAsCSV(csv, 'aanvragen');
    }


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        if (calendarEvents) {
            setSelectedEvent((prevEvent) => {
                if (prevEvent) {
                    let event = allItems.find(x => x.$id == prevEvent.$id);
                    if (event) {
                        return event;
                    }
                }
                return null;
            })
        }
    }, [calendarEvents])

    return <div className="lg:w-[70vw] mx-auto lg:p-5 flex flex-col gap-8">
        <EventDetailsDialog event={selectedEvent} onClose={() => setSelectedEvent(null)} />
        <h1 className="text-2xl font-bold">Aanvragen</h1>


        <div className="flex flex-col gap-4 py-8 text-sm bg-white/70 border rounded-xl shadow-md border-gray-200 ">
            <div className="flex flex-row items-center gap-4 px-4">
                <div className="flex flex-row items-center">
                    <div className="flex-initial font-bold px-4">Toon</div>
                    <StatusSelection onSelectStatus={setStatusFilter} currentStatus={statusFilter} />
                </div>
                <div className="flex flex-row items-center">
                    <div className="flex-initial font-bold px-4">Type</div>
                    <TypeSelection onSelectType={(type) => setTypeFilter(type != 'all' ? type : '')} currentType={typeFilter} />
                </div>
                <div className="flex flex-row items-center flex-1">
                    <div className="flex-initial font-bold px-4">Zoek</div>
                    <div className="flex-1">
                        <TextInput className={`w-4/5`} onChange={(e) => setSearchString(e.target.value)} value={searchString} placeholder="Zoeken op naam, bedrijfsnaam of email" />
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <button className="text-white bg-purple-500 rounded-full px-8 py-3" onClick={() => exportEvents(filteredItems)}>Export</button>
                </div>
            </div>

            <div className="flex flex-row items-center gap-4 px-4">
                <div className="flex flex-row items-center">
                    <div className="flex-initial font-bold px-4">Van/tot:</div>
                    <PeriodSelection onSelectPeriod={(period) => setPeriodFilter(period)} currentPeriod={periodFilter} />
                </div>
                <div className="flex flex-row items-center">
                    <div className="flex-initial font-bold px-4">Lunch:</div>
                    <LunchSelection onSelectLunch={(lunch) => setLunchFilter(lunch)} currentLunch={lunchFilter} />
                </div>
            </div>

        </div>


        <div className="">
            
            {/* <div className="flex items-center justify-center">
                <div className="w-[70%] pb-4">
                    <TextInput onChange={(e) => setSearchString(e.target.value)} value={searchString} placeholder="Zoeken op naam, bedrijfsnaam of email" />
                </div>
            </div> */}
            


            <table className="w-full bg-white pt-12 rounded-xl shadow-md border border-gray-200">
                <thead>
                    <tr>
                        <td colSpan="5" className="bg-gray-100/70 px-4 py-4 border-y border-gray-200"></td>
                    </tr>
                    <tr>

                        <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 pl-8 ">
                            <div className={`flex items-center`}>
                                <div>Ingediend op</div>
                                { sortBy != 'date' && <button className="ml-2" onClick={() => setSortBy('date')}><ChevronRightIcon className="w-4" /></button>}
                                { dateSort == 'desc' && sortBy == 'date' && <button className="ml-2" onClick={() => setDateSort('asc')}><ChevronUpIcon className="w-4" /></button>}
                                { dateSort == 'asc' && sortBy == 'date' && <button className="ml-2" onClick={() => setDateSort('desc')}><ChevronDownIcon className="w-4" /></button>}
                            </div>
                        </th>
                        <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 text-left">
                            Gegevens
                        </th>
                        <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 text-left">
                            E-mail
                        </th>
                        <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 text-left ">
                            Type + aantal
                        </th>
                        <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 text-left ">

                            <div className={`flex items-center`}>
                                <div>Datum event aanvraag</div>
                                { sortBy != 'calendarDate' && <button className="ml-2" onClick={() => setSortBy('calendarDate')}><ChevronRightIcon className="w-4" /></button>}
                                { calendarDateSort == 'desc' && sortBy == 'calendarDate' && <button className="ml-2" onClick={() => setCalendarDateSort('asc')}><ChevronUpIcon className="w-4" /></button>}
                                { calendarDateSort == 'asc' && sortBy == 'calendarDate' && <button className="ml-2" onClick={() => setCalendarDateSort('desc')}><ChevronDownIcon className="w-4" /></button>}
                            </div>
                        </th>
                    </tr>
                </thead>
               
               <tbody>
      
                    {(currentItems || []).map((event) => {
                        let eventDetails = calendarEventsDetails[event.$id];
                        let status = "pending";
                        let companyName = "";
                        let name = "";
                        let email = "";
                        let count = 0;
                        if (eventDetails && Object.keys(eventDetails).length > 0) {
                            let firstEvent = Object.values(eventDetails)[0];
                            status = event.status;
                            switch (event.$$type) {
                                case 'aanpakker':
                                    let firstParticipant = Object.values(participants[event.$id] || {})[0];
                                    companyName = firstEvent.organizationName;
                                    if(firstParticipant) {
                                        name = `${firstParticipant.firstName} ${firstParticipant.lastName}`;
                                        email = firstParticipant.emailAddress;
                                        count = Object.values(participants[event.$id]).length;
                                    }
                                    break;
                                case 'teamuitje':
                                    status = "pending";
                                    companyName = firstEvent.organizationName;
                                    name = `${firstEvent.firstName} ${firstEvent.lastName}`;
                                    email = firstEvent.emailAddress;
                                    count = firstEvent.amountOfParticipants;
                                    break;
                                case 'heldenavond':
                                    status = "rejected";
                                    name = `${firstEvent.firstName} ${firstEvent.lastName}`;
                                    email = firstEvent.emailAddress;
                                    count = 1;
                                    break;
                                case 'maatwerk':
                                    companyName = firstEvent.organizationName;
                                    name = `${firstEvent.primaryContactFirstName} ${firstEvent.primaryContactLastName}`;
                                    email = firstEvent.primaryContactEmailAddress;
                                    count = 1;
                                    break;
                            }
                        }
                        let daypart = event.daypart == "morning" ? "ochtend" : "middag";
                        daypart = event.daypart ? `(${daypart})` : '';
                        return <tr key={event.$id}>
                            <td className={`border-b border-gray-200`}>
                                <div className={`p-4 pl-8  cursor-pointer`} onClick={() => setSelectedEvent(event)}>{formatDateFromISO(event.$createdAt)}</div>
                            </td>
                            <td className={`border-b border-gray-200`}>
                                <div className={`p-4  cursor-pointer`} onClick={() => setSelectedEvent(event)}>
                                    <span className="font-bold">Bedrijf:</span> {companyName}<br />
                                    <span className="font-bold">Naam:</span>  {name}
                                
                                </div>
                            </td>
                            <td className={`border-b border-gray-200`}>
                                <div className={`p-4  cursor-pointer`} onClick={() => setSelectedEvent(event)}>{email}</div>
                            </td>
                            <td className={`border-b border-gray-200`}>
                                <div className={`p-4  cursor-pointer`} onClick={() => setSelectedEvent(event)}>{event.$$type} ({count})</div>
                            </td>
                            <td className={`border-b border-gray-200`}>
                                <div className={`p-4 pl-8  cursor-pointer`} onClick={() => setSelectedEvent(event)}>{formatDateFromISO(event.date)}</div>
                            </td>
                            
                            {/* <div className={`p-4 pr-8 text-right  border-b border-gray-200 cursor-pointer`} onClick={() => setSelectedEvent(event)}></div> */}
                        </tr>
                    })}

                </tbody>
            </table>
            <div className="flex flex-row justify-between items-center mt-8">
                <div>
                    <label>Resultaten</label>
                    <span className="ml-2">{indexOfFirstItem + 1}</span>
                    <span className="mx-2">tot</span>
                    <span className="">{Math.min(indexOfLastItem, filteredItems.length)}</span>
                    <span className="mx-2">van de</span>
                    <span className="">{filteredItems.length} resultaten</span>

                </div>
                <div className="flex flex-row gap-4">
                    <button className="text-gray-700 disabled:text-gray-400 bg-gray-100 py-2 px-4 border border-gray-300 rounded-md" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage == 1}>
                        <div className="flex flex-row gap-2 items-center">
                            <div><ChevronLeftIcon className="w-4" /></div>
                            <div>Vorige</div>
                        </div>
                    </button>
                    <button className="text-gray-700 disabled:text-gray-400 bg-gray-100 py-2 px-4 border border-gray-300 rounded-md" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentItems.length < itemsPerPage}>
                        <div className="flex flex-row gap-2 items-center">
                            <div>Volgende</div>
                            <div> <ChevronRightIcon className="w-4" /></div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
}

function StatusSelection({ onSelectStatus, currentStatus}) {

    const statusOptions = {
        pending: "Aanvragen (pending)",
        confirmed: "Bevestigd (confirmed)",
        rejected: "Afgewezen (rejected)"
    }

    return (
        <Listbox value={currentStatus} onChange={onSelectStatus} className="">
            <div className="relative">
                <Listbox.Button className="px-4 py-3 bg-white rounded-xl text-gray-600 flex flex-row gap-4 border border-gray-300 items-center">
                    <div>{statusOptions[currentStatus]}</div>
                    <div><ChevronDownIcon className="w-4" /></div>
                </Listbox.Button>
            
                <Listbox.Options className="bg-white px-4 py-3  flex flex-col absolute left-0 shadow-md mt-2 w-full ui-open:z-10 ">
                    {Object.keys(statusOptions).map((status) => (
                        <Listbox.Option
                            key={status}
                            value={status}
                        >
                            {({ selected, active }) => <div className="flex flex-row gap-2 py-2 items-center whitespace-nowrap cursor-pointer  "><div> {statusOptions[status]}</div><div>{selected && <CheckIcon className="w-4" />} </div></div>}
                        
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    )

}

function LunchSelection({ onSelectLunch, currentLunch }) {

    const lunchOptions = {
        all: "Toon alles",
        lunch: "Aanvragen met lunch (alleen teamuitjes en maatwerk)",
        noLunch: "Aanvragen zonder lunch"
    }

    return (
        <Listbox value={currentLunch} onChange={onSelectLunch}>
            <div className="relative">
            <Listbox.Button className="px-4 py-3 bg-white rounded-xl text-gray-600 flex flex-row gap-4 border border-gray-300 items-center">
                <div>{lunchOptions[currentLunch]}</div>
                <div><ChevronDownIcon className="w-4" /></div>
            </Listbox.Button>
                <Listbox.Options className="bg-white px-4 py-3  flex flex-col absolute left-0 shadow-md mt-2">
                    {Object.keys(lunchOptions).map((lunch) => (
                        <Listbox.Option
                            key={lunch}
                            value={lunch}
                        >
                            {({ selected, active }) => <div className="flex flex-row gap-2 py-2 items-center whitespace-nowrap cursor-pointer"><div> {lunchOptions[lunch]}</div><div>{selected && <CheckIcon className="w-4" />} </div></div>}
                        
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    )

}

function PeriodSelection({ onSelectPeriod, currentPeriod }) {
    const periodOptions = {
        complete: "Gehele kalender",
        currentMonth: "Deze maand",
        currentWeek: "Deze week"
    }

    return (
        <Listbox value={currentPeriod} onChange={onSelectPeriod}>
            <div className="relative">
            <Listbox.Button className="px-4 py-2 bg-white rounded-xl text-gray-600 flex flex-row gap-4 border border-gray-300 items-center">
                <div className="text-black"><CalendarIcon className="w-7" /></div>
                <div>{periodOptions[currentPeriod]}</div>
                <div><ChevronDownIcon className="w-4" /></div>
            </Listbox.Button>
            
                <Listbox.Options className="bg-white px-4 py-3  flex flex-col absolute left-0 shadow-md mt-2">
                    {Object.keys(periodOptions).map((period) => (
                        <Listbox.Option
                            key={period}
                            value={period}
                            
                        >
                            {({ selected, active }) => <div className="flex flex-row gap-2 py-2 items-center whitespace-nowrap cursor-pointer"><div> {periodOptions[period]}</div><div>{selected && <CheckIcon className="w-4" />} </div></div>}
                        
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    )
}

export function TypeSelection({ onSelectType, currentType, defaultLabel="Alle type aanvragen"}) {

    let currentLabel = currentType;
    console.log(currentType);
    if(!currentType) {
        currentLabel = "all";
    }


    const typeOptions = {
        all: defaultLabel,
        aanpakker: "Aanpakker voor een dag",
        teamuitje: "Teamuitje",
        heldenavond: "Heldenavond",
        maatwerk: "Maatwerk"
    }

    return (
        <Listbox value={currentType} onChange={onSelectType}>
            <div className="relative">
            <Listbox.Button className="px-4 py-3 bg-white rounded-xl text-gray-600 flex flex-row gap-4 border border-gray-300 items-center ">
                <div>{typeOptions[currentLabel]}</div>
                <div><ChevronDownIcon className="w-4" /></div>
            </Listbox.Button>
            
                <Listbox.Options className="bg-white px-4 py-3  flex flex-col absolute left-0 shadow-md mt-2 ui-open:z-10">
                    {Object.keys(typeOptions).map((type) => (
                        <Listbox.Option
                            key={type}
                            value={type}
                        >
                            {({ selected, active }) => <div className="flex flex-row gap-2 py-2 items-center whitespace-nowrap cursor-pointer"><div> {typeOptions[type]}</div><div>{selected && <CheckIcon className="w-4" />} </div></div>}
                        
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    )

}



