import { useState, useEffect, useMemo } from "react";
import { formatDate, getDateObject } from "../Utils/date";
import { calendarLabels, statusClassName } from '../Pages/AdminCalendar';
import { useCalendarEventContext } from "../Context/CalendarEventContext";
import { useCalendarEventContextSelector } from "../Context/CalendarEventContextSelector";
import ToggleInput from "./Forms/Controls/ToggleInput";
import NumberInput from "./Forms/Controls/NumberInput";
import api from "../api/api";
import { Server } from "../Utils/config";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { set } from "date-fns";
import { getData } from "../Utils/form";
import { useBackupContext  } from "Context/BackupContext";


const unavailableDateFormFields = [
	'date',
	'teamuitjeMorningUnavailable',
	'teamuitjeAfternoonUnavailable',
	'heldenavondUnavailable',
	'aanpakkerMaximumAmountOfParticipants'
]

export default function DayActionsPanel({ selectedDay, onAdd, dayEvents = [], onEdit }) {

	const { backupMode, queueForRestore } = useBackupContext();
	const [loading, setLoading] = useState(false);
	const { unavailableDates, calendarEventsDetails, participants, reload } = useCalendarEventContextSelector();
	const [isSetAvailabilityActive, setIsSetAvailabilityActive] = useState(false);
	const [unavailableDateObject, setUnavailableDateObject] = useState({
		date: selectedDay,
		teamuitjeMorningUnavailable: true,
		teamuitjeAfternoonUnavailable: true,
		heldenavondUnavailable: true,
		aanpakkerMaximumAmountOfParticipants: 0
	});

	const onRestore = (e, event) => {
		e.stopPropagation();
		queueForRestore(event);
	}

	const onRestoreAll = () => {
		Object.values(dayEvents).forEach(event => queueForRestore(event));
	}

	useEffect(() => {
		if(selectedDay) {
			let unavailableDateObject = unavailableDates.find(x => x.date == selectedDay);
			if(unavailableDateObject) {
				setUnavailableDateObject(unavailableDateObject);
			} else {
				setUnavailableDateObject({
					date: selectedDay,
					teamuitjeMorningUnavailable: false,
					teamuitjeAfternoonUnavailable: false,
					heldenavondUnavailable: false,
					aanpakkerMaximumAmountOfParticipants: 10
				});
			}
		}
	}, [selectedDay, unavailableDates])

	// console.log("dayEvents in DayActionsPanel", dayEvents);

	let panelDayCards = dayEvents || {};
	
	panelDayCards = Object.values(panelDayCards);
	panelDayCards = panelDayCards.filter(x => x.status !== 'rejected');
	panelDayCards = panelDayCards.sort((x, y) => {

		const order = statusOrder.indexOf(x.status) - statusOrder.indexOf(y.status);

		if(order === 0)
			return new Date(x.$createdAt) - new Date(y.$createdAt);

		return order;

	});
	panelDayCards = panelDayCards.map(event => {
		let eventDetails = calendarEventsDetails[event.$id];
		let status = "pending";
		let name = "";
		let email = "";
		let count = 0;

		
		if(eventDetails && Object.keys(eventDetails).length > 0) {
			let firstEvent = Object.values(eventDetails)[0];
			status = event.status;
			// console.log("firstEvent", firstEvent)
			switch(event.$$type) {
				case 'aanpakker':
				  let participant = Object.values(participants[event.$id] || {})[0];
				  if(participant) {
					name = `${participant.firstName} ${participant.lastName}`;
					email = participant.emailAddress;
				  }
				break;
				case 'teamuitje':
					name = `${firstEvent.firstName} ${firstEvent.lastName}`;
					email = firstEvent.emailAddress;
				break;
				case 'heldenavond':
					name = `${firstEvent.firstName} ${firstEvent.lastName}`;
					email = firstEvent.emailAddress;
				break;
				case 'maatwerk':
				  	name = `${firstEvent.primaryContactFirstName} ${firstEvent.primaryContactLastName}`;
					email = firstEvent.primaryContactEmailAddress;
				
				break;
			}
		}

		return <li key={event.$id} className="text-sm flex flex-row gap-4 items-baseline w-full cursor-pointer"  onClick={() => onEdit(event)}>
			<div className={`flex-initial w-2 h-2 aspect-square ${statusClassName[status]}`}></div>
			<div className="flex-1 flex flex-col gap-2">
				<div>
					{calendarLabels[event.$$type]}
				</div>
				<div className="grid grid-cols-2 gap-4  whitespace-nowrap">
					<div className="">
						<span className="text-cal-blue">Naam:</span> {name || 'onbekend'}
					</div>
					<div className="">
						<span className="text-cal-blue">E-mail:</span> {email || 'onbekend'} 
					</div>
				</div>
			</div>
			<div className="flex-initial flex flex-row gap-2">
				<button className="text-cal-blue" onClick={() => onEdit(event)}>Bekijk</button>
				{ backupMode && <button className="text-red-500" onClick={(e) => onRestore(e, event)}>Herstel</button> }
			</div>
		</li>

	});

	const pendingOrApprovedEventFilter = (event) => event.status !== 'rejected';

	const canAdd = (type, dayEvents) => {
		let events = (dayEvents && Object.values(dayEvents) || []).filter(x => x.$$type == type).filter(pendingOrApprovedEventFilter);
		
		if(type == 'teamuitje' && events.length >= 2) {
			return false;
		}

		if(type == 'heldenavond' && events.length >= 21) {
			return false;
		}

		if(type == 'aanpakker' && events.length >= 10) {
			return false;
		}

		// maatwerk is altijd toegestaan
	
		return true;
	}

	let availabilityLabel = "Datum niet beschikbaar";

	// TODO: hier moet een extra controle op komen, omdat deze wat uitgebreider gaat worden
	const isFullyAvailable = (() => {
		let x = unavailableDateObject;
		if(!x) return true;

		return !x.teamuitjeMorningUnavailable &&
		!x.teamuitjeAfternoonUnavailable && 
		!x.heldenavondUnavailable && 
		x.aanpakkerMaximumAmountOfParticipants == 10;
	})();

	const isPartlyAvailable = !isFullyAvailable && (() => {
		let x = unavailableDateObject;
		if(!x) return false;

		return !x.teamuitjeMorningUnavailable || 
		!x.teamuitjeAfternoonUnavailable || 
		!x.heldenavondUnavailable || 
		x.aanpakkerMaximumAmountOfParticipants > 0;
	})();
	const isAvailable = isFullyAvailable || isPartlyAvailable;

	if(isAvailable && isPartlyAvailable) {
		availabilityLabel = "Datum deels beschikbaar";
	} else if(isAvailable) {
		availabilityLabel = "Datum beschikbaar";
	}

	const onChangeDateAvailability = async (valueCallback) => {
		let value = valueCallback(isAvailable);
		let saveObject = {
			...unavailableDateObject,
		}
		if(value) {
			saveObject.teamuitjeMorningUnavailable = false;
			saveObject.teamuitjeAfternoonUnavailable = false;
			saveObject.heldenavondUnavailable = false;
			saveObject.aanpakkerMaximumAmountOfParticipants = 10;
		} else {
			saveObject.teamuitjeMorningUnavailable = true;
			saveObject.teamuitjeAfternoonUnavailable = true;
			saveObject.heldenavondUnavailable = true;
			saveObject.aanpakkerMaximumAmountOfParticipants = 0;
		}
		setLoading(true);
		let result = saveObject;
		if(!saveObject.$id) {
			await api.createDocument(Server.databaseID, Server.collections['UnavailableDate'], getData(saveObject, unavailableDateFormFields));
			reload();
		} else {
			result = await api.updateDocument(Server.databaseID, Server.collections['UnavailableDate'], saveObject.$id, getData(saveObject, unavailableDateFormFields));
			reload();
		}
		setLoading(false);
		setUnavailableDateObject(result);
	}

	const onAvailabilityChange = async (changedObject) => {
		
		let result = changedObject;
		setLoading(true);
		if(!changedObject.$id) {
			result = await api.createDocument(Server.databaseID, Server.collections['UnavailableDate'], getData(changedObject, unavailableDateFormFields));
			reload();
		} else {
			await api.updateDocument(Server.databaseID, Server.collections['UnavailableDate'], changedObject.$id, getData(changedObject, unavailableDateFormFields));
			reload();
		}
		setLoading(false);
		setUnavailableDateObject(result);
		
		
	}

	const selectedDateObject = selectedDay && getDateObject(selectedDay);


	return <div className="text-sm grid grid-cols-2 gap-4 relative">
		{loading && <div className="w-full h-full absolute cursor-wait"></div> }
		
		<div className={`${!isSetAvailabilityActive ? 'col-span-2' : ''}`}>
			<h2 className="text-cal-blue">{selectedDay && formatDate(selectedDay)}</h2>
			<div className="py-4 border-b grid grid-rows-2 gap-4">
				<div className="flex flex-row items-center justify-between">
					<div className="">
						{availabilityLabel}
					</div>
					<ToggleInput value={isAvailable} onChange={onChangeDateAvailability} isPartialActive={isPartlyAvailable} className="bg-orange-600" />
				</div>
				<div className="">
					<button className="text-cal-blue flex flex-row items-center" onClick={() => setIsSetAvailabilityActive(x => !x)}>
						<span>Beschikbaarheid instellen</span> <ChevronRightIcon className="inline-block w-6 h-6"/>
					</button>
					{ backupMode && <button className="text-red-500" onClick={onRestoreAll}>Herstel alle evenementen</button> }
				</div>
				
				
			</div>

			<div className="py-4 border-b">
				<div className="text-cal-blue pb-4">Op deze dag:</div>
				{ panelDayCards.length < 1 &&
					<div className="">
						Er zijn (nog) geen events ingepland
					</div> ||
					<div>
						<ul className="flex flex-col gap-6">
							{panelDayCards}
						</ul>
					</div>
				}
			</div>

			<div className="py-4 flex flex-col gap-4">
				<div className="text-cal-blue">
					Voeg event toe:
				</div>
				<div className="flex flex-row gap-4">
					<button className="bg-purple-500 disabled:bg-purple-500/50 rounded-3xl text-white px-4 py-2" disabled={!canAdd('teamuitje', dayEvents) || !isAvailable || backupMode} onClick={() => onAdd("teamuitje", Object.values(dayEvents).filter(x => x.$$type == 'teamuitje' && pendingOrApprovedEventFilter(x)).map(x => x.daypart))}>Teamuitje</button>
					{ selectedDateObject && selectedDateObject.getDay() == 2 && <button className="bg-purple-500 disabled:bg-purple-500/50 rounded-3xl text-white px-4 py-2" disabled={!canAdd('heldenavond', dayEvents) || !isAvailable || backupMode} onClick={() => onAdd("heldenavond")}>Heldenavond</button> }
					<button className="bg-purple-500 disabled:bg-purple-500/50 rounded-3xl text-white px-4 py-2" disabled={!canAdd('aanpakker', dayEvents) || !isAvailable || backupMode} onClick={() => onAdd("aanpakker")}>Aanpakker</button>
					<button className="bg-purple-500 disabled:bg-purple-500/50 rounded-3xl text-white px-4 py-2" disabled={!canAdd('maatwerk', dayEvents) || !isAvailable || backupMode} onClick={() => onAdd("maatwerk")}>Maatwerk</button>
				</div>
			</div>
		</div>

		<AvailabilityPanel backupMode={backupMode} onSave={onAvailabilityChange} selectedDay={selectedDay} onCancel={() => setIsSetAvailabilityActive(x => !x)} isActive={isSetAvailabilityActive} unavailableDateObject={unavailableDateObject} />
	</div>
}

const statusOrder = [
	'pending',
	'confirmed',
	'rejected'
];


function AvailabilityPanel({ selectedDay, isActive, unavailableDateObject, onSave, onCancel, backupMode }) {

	const [availabilityForm, setAvailabilityForm] = useState(unavailableDateObject);

	let { 
		teamuitjeAfternoonUnavailable, 
		teamuitjeMorningUnavailable, 
		heldenavondUnavailable, 
		aanpakkerMaximumAmountOfParticipants 
	} = availabilityForm;

	const onValueChange = (field, value) => {
		setAvailabilityForm((availabilityForm) => ({...availabilityForm, [field]: value}));
	}

	useEffect(() => {
		if(unavailableDateObject) {
			setAvailabilityForm(unavailableDateObject);
		
		}
	}, [unavailableDateObject])

	return <div className={`${isActive ? 'block' : 'hidden'} border-l px-4 flex flex-col gap-4`}>

		<div className={`border-b pb-4`}>
			<button className={`text-cal-blue flex flex-row items-center gap-1`} onClick={onCancel}>
				<ChevronLeftIcon className="inline-block w-6 h-6 font-bold"/>
				<span>Instellingen voor beschikbaarheid</span>
			</button>
		</div>

		<div className="border-b flex flex-col gap-4 pb-4">
			<div className="text-cal-blue">Teamuitjes</div>
			<div className="flex flex-row items-center justify-between">
				<div className="">
					Ochtend {teamuitjeMorningUnavailable ? 'niet' : ''} beschikbaar
				</div>
				<ToggleInput value={teamuitjeMorningUnavailable} onChange={(v) => onValueChange('teamuitjeMorningUnavailable', v(teamuitjeMorningUnavailable))} />
			</div>
			<div className="flex flex-row items-center justify-between">
				<div className="">
					Middag {teamuitjeAfternoonUnavailable ? 'niet' : ''} beschikbaar
				</div>
				<ToggleInput value={teamuitjeAfternoonUnavailable} onChange={(v) => onValueChange('teamuitjeAfternoonUnavailable', v(teamuitjeAfternoonUnavailable))} />
			</div>
		</div>

		<div className="border-b flex flex-col gap-4 pb-4">
			<div className="text-cal-blue">Heldenavond</div>
			<div className="flex flex-row items-center justify-between">
				<div className="">
					Vandaag {heldenavondUnavailable ? 'niet' : ''} beschikbaar
				</div>
				<ToggleInput value={heldenavondUnavailable} onChange={(v) => onValueChange('heldenavondUnavailable', v(heldenavondUnavailable))} />
			</div>
			
		</div>

		<div className="flex flex-col gap-2 ">
			<div className="text-cal-blue">Aanpakker voor een dag</div>
			
			<div className="flex flex-row items-center justify-between gap-4">
				<div className="">
					Aantal personen/plaatsen beschikbaar
				</div>
				<NumberInput 
					hasError={false} 
					minAllowed={0} 
					maxAllowed={10} 
					value={aanpakkerMaximumAmountOfParticipants} 
					onChange={(v) => onValueChange('aanpakkerMaximumAmountOfParticipants', v)}  />
			</div>

			<div className="flex flex-row items-end justify-end pt-4">
				<button disabled={backupMode} className="bg-cal-blue disabled:bg-cal-blue/70 text-white rounded-3xl px-4 py-2" onClick={() => onSave(availabilityForm)}>Beschikbaarheid opslaan</button>
			</div>

		</div>


			
	</div>
}
